import React from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { useState } from "react";
import "../assets/css/style.css";

const API_Key = "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";

function ChatBot() {
  const [userResponses, setUserResponses] = useState({});
  const [typingResponse, setTypingResponse] = useState(false);
  const [questionsCompleted, setQuestionsCompleted] = useState(true);
  const [messages, setMessages] = useState([
    {
      message: "How can I help you?",
      sender: "chatbot",
    },
  ]);

  const systemMessage = {
    role: "system",
    content: `Explain things like you're talking to a ${userResponses?.Experience} ${userResponses?.Role} in a ${userResponses?.Domain} domain and provide them the guidance to get into the industry, interview processes etc..`,
  };

  const handleSend = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setTypingResponse(true);
    await parseUserMessage(newMessages);
  };

  async function parseUserMessage(chatbotMessages) {
    let apiMessages = chatbotMessages.map(messageObject => {
      let role = "";
      if (messageObject.sender === "chatbot") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_Key,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then(data => {
        return data.json();
      })
      .then(data => {
        setMessages([
          ...chatbotMessages,
          {
            message: data.choices[0].message.content,
            sender: "chatbot",
          },
        ]);
        setTypingResponse(false);
      });
  }

  const handleUserResponse = async (question, answer) => {
    const newMessage = {
      message: `You selected ${answer} for ${question}`,
      sender: "user",
    };

    setUserResponses(prevResponses => ({
      ...prevResponses,
      [question]: answer,
    }));

    // setTypingResponse(true);
    // await parseUserMessage([...messages, newMessage]);
  };

  const componentStyles = {
    welcomeContainer: {
      height: "50px",
      width: "100%",
      borderRadius: "10px",
      backgroundColor: "#fa496e",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "15px",
      fontSize: "30px",
      fontFamily: "serif",
      fontWeight: "600",
    },

    questionsSpan: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#6c757d",
      display: "block",
    },

    labelTextStyling: {
      fontSize: "16px",
      marginRight: "20px",
    },

    radioInputStyling: {
      marginRight: "5px",
    },
  };

  return (
    <div>
      <div
        style={{
          height: "550px",
          width: "450px",
        }}
      >
        <MainContainer style={{ borderRadius: "20px" }}>
          <ChatContainer>
            <MessageList
              typingIndicator={
                typingResponse ? (
                  <TypingIndicator content="Response getting ready ..." />
                ) : null
              }
            >
              <div
                className="chatbot_container"
                style={componentStyles.welcomeContainer}
              >
                <h3>Excellium Chatbot</h3>
              </div>

              <div>
                <span
                  style={{
                    ...componentStyles.questionsSpan,
                    marginBottom: "20px",
                  }}
                >
                  Select your preferences to proceed furthur!
                </span>
              </div>

              {messages && (
                <div>
                  <span style={componentStyles.questionsSpan}>
                    Are you a fresh graduate or an experienced person?
                  </span>
                  <div style={componentStyles.radioOptionsContainer}>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="fresh"
                        name="experienceOption"
                        onChange={() =>
                          handleUserResponse("Experience", "Fresh Graduate")
                        }
                        style={componentStyles.radioInputStyling}
                      />
                      Fresh Graduate
                    </label>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="experienced"
                        name="experienceOption"
                        onChange={() =>
                          handleUserResponse("Experience", "Experienced Person")
                        }
                        style={componentStyles.radioInputStyling}
                      />
                      Experienced Person
                    </label>
                  </div>
                </div>
              )}

              {messages && (
                <div>
                  <span style={componentStyles.questionsSpan}>
                    Which role are you interested in? (SQA or SE)
                  </span>
                  <div style={componentStyles.radioOptionsContainer}>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="SQA"
                        name="role"
                        onChange={() => handleUserResponse("Role", "SQA")}
                        style={componentStyles.radioInputStyling}
                      />
                      QA Engineer
                    </label>

                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="SE"
                        name="role"
                        onChange={() => handleUserResponse("Role", "SE")}
                        style={componentStyles.radioInputStyling}
                      />
                      Software Engineer
                    </label>
                  </div>
                </div>
              )}

              {messages && (
                <div>
                  <span style={componentStyles.questionsSpan}>
                    In which domain are you interested? (Web, App, AI/ML, Game
                    Development)
                  </span>
                  <div style={componentStyles.radioOptionsContainer}>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="Web"
                        name="domain"
                        onChange={() => handleUserResponse("Domain", "Web")}
                        style={componentStyles.radioInputStyling}
                      />
                      Web
                    </label>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="App"
                        name="domain"
                        onChange={() => handleUserResponse("Domain", "App")}
                        style={componentStyles.radioInputStyling}
                      />
                      App
                    </label>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="AI/ML"
                        name="domain"
                        onChange={() => handleUserResponse("Domain", "AI/ML")}
                        style={componentStyles.radioInputStyling}
                      />
                      AI/ML
                    </label>
                    <label style={componentStyles.labelTextStyling}>
                      <input
                        type="radio"
                        value="Game Development"
                        name="domain"
                        onChange={() =>
                          handleUserResponse("Domain", "Game Development")
                        }
                        style={componentStyles.radioInputStyling}
                      />
                      Game Development
                    </label>
                  </div>
                </div>
              )}
              {(
                (("Experience" && "Role" && "Domain") in userResponses &&
                  messages) ||
                []
              ).map((message, i) => {
                return (
                  <Message
                    key={i}
                    model={message}
                    style={{ padding: "10px" }}
                  />
                );
              })}
            </MessageList>
            <MessageInput
              disabled={
                ("Experience" && "Role" && "Domain") in userResponses
                  ? false
                  : true
              }
              placeholder="Write your query ..."
              onSend={handleSend}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
}

export default ChatBot;
