import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { imageData } from "./ImageData";

function ImageGallery() {
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          marginBottom: "2.5rem",
        }}
      >
        <div className="block-title text-center" style={{ marginTop: "1rem" }}>
          <h3>Our Articles</h3>
        </div>
        <Carousel autoPlay interval={3000} transitionTime={1000} infiniteLoop>
          {imageData.map((image, index) => (
            <div
              key={index}
              onClick={() => window.open(image.link, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img src={image.imageUrl} alt={`Article ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ImageGallery;
