import React from "react";
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import "./style.css";

export const SliderCard = React.memo(function (StackedCarouselSlideProps) {
  const {
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex,
  } = StackedCarouselSlideProps;

  const coverImage = data[dataIndex].image;
  const text = data[dataIndex].text;
  const link = data[dataIndex].link;

  const handleImageClick = () => {
    if (!isCenterSlide) {
      swipeTo(slideIndex);
    }
  };

  return (
    <div className="card-card" draggable={false}>
      <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
        <div className="card-overlay fill" onClick={handleImageClick} />
      </div>

      <a href={link} target="_blank">
        <div className="detail fill">
          <div className="discription">
            <img
              style={{ width: "430px", height: "100%", objectFit: "cover" }}
              alt="j"
              className="cover-image"
              src={coverImage}
            />
            {/* <p>{text}</p> */}
          </div>
        </div>
      </a>
    </div>
  );
});
