import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import { SliderCard } from "./SliderCard";
import { Button } from "react-bootstrap";
import OOPBannerImage from "../../assets/images/publications-banner/OOP.png";
import DSABannerImage from "../../assets/images/publications-banner/DSA.png";
import DBBannerImage from "../../assets/images/publications-banner/DB.png";
import APIBannerImage from "../../assets/images/publications-banner/API.png";

const data = [
  {
    image: OOPBannerImage,
    text: "one",
    link:
      "https://docs.google.com/presentation/d/1lGINZTIHt734RX9M9KIQLAxJiJC6uyoI8_AQIbbfk7E/edit#slide=id.g214f432419e_0_258",
  },
  {
    image: DSABannerImage,
    text: "two",
    link:
      "https://docs.google.com/presentation/d/1WHvoOAz91087igbEdNt94wA9q9fP_vwkJeCIZUp4-UI/edit#slide=id.g216ed2f3175_0_207",
  },
  {
    image: DBBannerImage,
    text: "three",
    link:
      "https://docs.google.com/presentation/d/15WIYbIovf6JVG1ppACM1TS10-jfLO7mq205buSUWqk0/edit#slide=id.p",
  },
  {
    image: APIBannerImage,
    text: "four",
    link:
      "https://docs.google.com/presentation/d/1baavyX_JdJvIqs8FWXulN7WCljX8f1-i_yS2Kfp3z9M/edit#slide=id.p",
  },
];

function Pagination(props) {
  const { centerSlideDataIndex, updatePosition } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        marginTop: 40,
      }}
    >
      {data.map((_, index) => {
        const isCenterSlide = props.centerSlideDataIndex === index;
        return (
          <div
            onClick={() => {
              updatePosition(index);
            }}
            style={{
              height: 15,
              width: 15,
              borderRadius: "100%",
              background: isCenterSlide ? "black" : "none",
              border: "1px solid black",
              cursor: "pointer",
            }}
          />
        );
      })}
    </div>
  );
}

const PublicationCorousel = () => {
  const ref = React.useRef(StackedCarousel);
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = newIndex => {
    setCenterSlideDataIndex(newIndex);
  };

  const updatePosition = index => {
    ref?.current?.swipeTo(index - centerSlideDataIndex);
  };

  return (
    <div id="publications" className="card" style={{ height: "35rem" }}>
      <div
        className="block-title text-center"
        style={{ marginTop: "1rem", marginBottom: "12px" }}
      >
        <h3>Our Publications</h3>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(width, carouselRef) => {
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={SliderCard}
                slideWidth={450}
                carouselWidth={width}
                data={data}
                maxVisibleSlide={5}
                disableSwipe
                customScales={[1, 0.85, 0.7, 0.55]}
                transitionTime={450}
                onActiveSlideChange={onCenterSlideDataIndexChange}
              />
            );
          }}
        />
        <div
          className="card-button left"
          size="small"
          onClick={() => ref.current?.goBack()}
          style={{ paddingLeft: "2rem", zIndex: "999", cursor: "pointer" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-caret-left-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm10.5 10V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4A.5.5 0 0 0 10.5 12" />
          </svg>
        </div>
        <div
          className="card-button right"
          size="small"
          onClick={() => ref.current?.goNext()}
          style={{ marginRight: "2rem", zIndex: "999", cursor: "pointer" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-caret-right-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4z" />
          </svg>
        </div>
      </div>
      <Pagination
        updatePosition={updatePosition}
        centerSlideDataIndex={centerSlideDataIndex}
      />
    </div>
  );
};

export default PublicationCorousel;
