import reactIconImage from "../assets/images/tools-images/react_js.svg";
import typescriptIconImage from "../assets/images/tools-images/typescript.png";
import nodeIconImage from "../assets/images/tools-images/nodejs.jpg";
import expressIconImage from "../assets/images/tools-images/express.jpg";
import chatbotIconImage from "../assets/images/tools-images/chatbots.jpg";
import firebaseIconImage from "../assets/images/tools-images/Firebase.png";
import RailsIconImage from "../assets/images/tools-images/ror.png";
import fullStackIconImage from "../assets/images/tools-images/full-stack.png";

export const imagesData = [
  { id: 1, src: reactIconImage, alt: "React" },
  { id: 2, src: typescriptIconImage, alt: "TypeScript" },
  { id: 3, src: nodeIconImage, alt: "Node.js" },
  { id: 4, src: expressIconImage, alt: "Express.js" },
  { id: 5, src: chatbotIconImage, alt: "Chatbots" },
  { id: 6, src: RailsIconImage, alt: "Rails" },
  { id: 7, src: fullStackIconImage, alt: "Full Stack" },
];
