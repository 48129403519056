import buildingBlocksArticleImage from "../../assets/images/article-images/building_blocks_of_ui.webp";
import cleanCodeArticleImage from "../../assets/images/article-images/writing_clean_code.webp";
import reactRoadmapArticleImage from "../../assets/images/article-images/React_roadmap.webp";
import propConceptArticleImage from "../../assets/images/article-images/props_and_state.webp";
import useStateArticleImage from "../../assets/images/article-images/useState.webp";
import reactHooksArticleImage from "../../assets/images/article-images/react_hooks.webp";
import imageUploadingArticleImage from "../../assets/images/article-images/image_upload.webp";
import lazyLoadingArticleImage from "../../assets/images/article-images/lazy_loading.webp";
import typescriptArticleImage from "../../assets/images/article-images/typescript.webp";
import jestArticleImage from "../../assets/images/article-images/jest.webp";
import momentArticleArticleImage from "../../assets/images/article-images/date_time_moment.webp";
import chartArticleImage from "../../assets/images/article-images/chart_js.webp";
import nextJsArticleImage from "../../assets/images/article-images/next_js.webp";
import microservicesArticleImage from "../../assets/images/article-images/Microservices.webp";
import herokuArticleImage from "../../assets/images/article-images/heroku.webp";
import communicationArticleImage from "../../assets/images/article-images/communication.webp";

export const imageData = [
  {
    imageUrl: buildingBlocksArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/react-components-building-blocks-of-ui-b27fc88006a4",
  },
  {
    imageUrl: cleanCodeArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/best-practices-for-react-js-development-writing-clean-maintainable-and-performant-code-19d1b7efe8fb",
  },
  {
    imageUrl: reactRoadmapArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/mastering-javascript-fundamentals-a-roadmap-series-for-react-js-developers-87bc3621ce7f",
  },
  {
    imageUrl: propConceptArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/react-props-and-state-managing-data-in-components-381f22ff70c7",
  },
  {
    imageUrl: useStateArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/understanding-the-power-of-usestate-hook-a-comprehensive-guide-with-examples-515c49021c0d",
  },
  {
    imageUrl: reactHooksArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/mastering-react-hooks-simplify-state-management-and-enhance-performance-1f87af85b7eb",
  },
  {
    imageUrl: lazyLoadingArticleImage,
    link:
      "https://medium.com/@muhammadabdullah101998/what-is-lazy-loading-b89c4c0fbb46",
  },
  {
    imageUrl: imageUploadingArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/in-modern-web-applications-image-uploads-are-a-common-requirement-but-implementing-a-secure-and-4ccde4358cbe",
  },
  {
    imageUrl: momentArticleArticleImage,
    link:
      "https://medium.com/@syedqaisartanvir/mastering-date-and-time-in-javascript-the-moment-js-way-73dc0d488cd7",
  },
  {
    imageUrl: chartArticleImage,
    link: "https://medium.com/@muhammadabdullah101998/chart-js-f18ef1ad2c67",
  },
  {
    imageUrl: jestArticleImage,
    link:
      "https://medium.com/@uafshahzaib/getting-started-with-jest-writing-your-first-unit-test-case-in-a-react-project-41ea8b3c6dfa",
  },
  {
    imageUrl: typescriptArticleImage,
    link:
      "https://medium.com/@muhammadabdullah101998/briefly-introduce-typescript-and-its-relationship-to-javascript-6b23eecdbc30",
  },
  {
    imageUrl: nextJsArticleImage,
    link: "https://medium.com/@uafshahzaib/introduction-8cf00b56bc8e",
  },
  {
    imageUrl: microservicesArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/introduction-in-todays-rapidly-evolving-technological-landscape-businesses-are-constantly-5975218d0d7c",
  },
  {
    imageUrl: herokuArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/if-you-are-a-devops-engineer-or-a-developer-working-on-a-project-hosted-on-heroku-managing-your-7534f1575575",
  },
  {
    imageUrl: communicationArticleImage,
    link:
      "https://medium.com/@muhammadjahanzaib_1449/introduction-a03399bcd490",
  },
];
